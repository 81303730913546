<template>
  <div class="service">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="时间">
        <el-date-picker
          is-range
          v-model="form.time"
          format="yyyy-MM-dd"
          clearable
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="width: 280px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updata">更新</el-button>
        <!-- <el-button type="primary" icon="el-icon-search" @click="exportData">生成表格</el-button> -->
        <download-excel v-if="derivedForm" class="export" :data="derivedForm" :fields="jsonFields" type="xls" name="手续费流水表.xls">
          下载表格
        </download-excel>
      </el-form-item>
    </el-form>
    <div class="fsdfsd">
      <div>总手续费金额 ：{{ $setNumbers(addall) }}</div>
      <!-- <div class="nonnik">减少 ：{{ $setNumbers(downall) }}</div> -->
    </div>
    <!-- 底部分页 -->
    <!-- <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        time: '',

        currentPage: 1,
        pageSize: 10
      },
      num: 0,
      derivedForm: '',
      timer: null,
      formLabelAlign: {
        amount: '',
        certificateId: 0,
        type: '',
        pwd: '',
        userId: ''
      },
      jsonFields: {
        手续费类型: 'dealType',
        金额: 'amount',
        创建时间: 'createTime'
      },
      addall: '',
      downall: '',
      centerDialogVisible: false,
      tableData: [],
      total: 0
    }
  },
  mounted() {
    this.getData(1)
    // this.getSeletor()
  },
  methods: {
    getData(type) {
      console.log(this.form)
      this.axios.get('/admin/userWater/getServerChangeWater', {}).then(res => {
        if (res.data.code == 200) {
          console.log(res)
          this.addall = res.data.data.all
          // this.downall = res.data.data.dowm
          this.stop()
          if (type == 2) {
            this.$message({
              message: '更新完成，请下载',
              type: 'success'
            })
            this.form.time = ''
            this.derivedForm = res.data.data.serverChangeVO
            this.derivedForm.forEach(item => {
              if (item.dealType == 1) {
                item.dealType = '充值手续费'
              } else if (item.dealType == 2) {
                item.dealType = '提现手续费'
              } else if (item.dealType == 3) {
                item.dealType = '通证流转手续费'
              } else if (item.dealType == 4) {
                item.dealType = '交易买入手续费'
              } else if (item.dealType == 6) {
                item.dealType = '企业提现手续费'
              } else if (item.dealType == 7) {
                item.dealType = '钰荟通证手续费'
              } else {
                item.dealType = '交易卖出手续费'
              }
            })
            // this.exportData()
          }
        }
      })
      // this.axios.get('/admin/userWater/getServerChangeWater', this.form).then(res => {
      //   console.log(res, '111111111111')
      //   this.addall = res.data.data.add
      //   this.downall = res.data.data.dowm
      //   this.tableData = res.data.data.serverChangeVO.list
      //   this.total = res.data.data.serverChangeVO.total
      // })
    },
    // 更新
    updata() {
      // this.$message({
      //   message: '表格生成中，请稍后',
      //   type: 'success'
      // })
      // this.axios.get('/admin/userWater/testWater2', { startTime: this.form.time[0], endTime: this.form.time[1] }).then(res => {
      //   console.log(res)
      //   this.derivedForm = res.data.data.serverChangeVO
      //   this.derivedForm.forEach(item => {
      //     if (item.dealType == 1) {
      //       item.dealType = '充值手续费'
      //     } else if (item.dealType == 2) {
      //       item.dealType = '提现手续费'
      //     } else if (item.dealType == 3) {
      //       item.dealType = '通证流转手续费'
      //     } else if (item.dealType == 4) {
      //       item.dealType = '交易买入手续费'
      //     } else {
      //       item.dealType = '交易卖出手续费'
      //     }
      //   })
      // })
      this.axios.get('/admin/userWater/getServerChange', { startTime: this.form.time[0], endTime: this.form.time[1] }).then(res => {
        console.log(res, '66666666666')
        this.$message({
          message: '开始更新表格，稍后',
          type: 'success'
        })
        this.timer = window.setInterval(() => {
          setTimeout(this.getProjectList(), 0)
        }, 3000)
        // if (res.data.code == 200) {
        //   console.log(res, '111111111111')
        //   this.addall = res.data.data.add
        //   this.downall = res.data.data.dowm
        //   this.tableData = res.data.data.serverChangeVO.str
        //   this.total = res.data.data.serverChangeVO.total
        // }
      })
    },
    // 获取所有需要导出的数据
    exportData() {
      console.log(2)
      this.axios.get('/admin/userWater/getTime', {}).then(res => {
        if (res.data.code == -1) {
          // 注释这里的原因是后端说这个接口太耗资源，该用列表接口
          // this.axios.get('/admin/userWithdraw/withdrawExcel', {}).then(res => {
          this.axios.get('/admin/userWater/getServerChange', { startTime: this.form.time[0], endTime: this.form.time[1] }).then(res => {
            console.log(res)
            // this.derivedForm = res.data.data
            this.form.pageSize = 10
            this.derivedForm = res.data.data.page.records
            this.derivedForm.forEach(item => {
              item.finish = item.finish == 0 ? '未成交' : item.finish == 1 ? '成交' : item.finish == 2 ? '部分成交' : '撤销'
              item.type = item.type == 1 ? '买入委托' : '卖出委托'
            })
          })
        } else {
          this.$message.error('本时段无法生成表格')
        }
      })
    },
    stop() {
      clearInterval(this.timer)
      this.timer = null
    },
    getProjectList() {
      this.getData(2)
      if (this.num == 20) {
        this.stop()
      }
    },

    // 获取数字通证下拉框
    getSeletor() {
      this.axios.get('/admin/certificate/getDropDownBox', {}).then(res => {
        console.log(res, '222222222222')
        this.options.push(...res.data.data)
        // this.options.push({ certificateId: 0, tokenName: 'CNY', tokenNum: '111111' })
      })
    },
    // 时间
    pickerChange(val) {
      this.form.startTime = val[0]
      this.form.endTime = val[1]
    },

    doFilter() {
      this.getData()
    },
    noserch() {
      this.form.userId = ''
      this.form.time = ''
      this.form.endTime = ''
      this.form.startTime = ''
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getData()
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.form.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.fsdfsd {
  display: flex;
  margin-bottom: 20px;
  font-weight: bold;
  .nonnik {
    margin-left: 20px;
  }
}
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
  .theQuery-Tow {
    width: 200px;
    height: 70px;
    border: 1px solid red;
    margin-right: 45px;
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
// 导出样式
.export {
  color: #fff;
  background-color: #409eff;
  width: 90px;
  min-width: 90px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  margin-left: 15px;
  display: inline-block;
}
.export:hover {
  cursor: pointer;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>